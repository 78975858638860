
<script>

import { log, webRequestManager } from 'organizer-common';

import { synchronizationService } from '@/service/SynchronizationService.js';

export default {
  name: 'ResourceCreateComponent',
  components: {
  },
  props: {
    parameterParent: Object,
    parameterPositionAfterResource: Object,
    category: String,
    depth: Number
  },
  data: function() {
    return {
      // Initialize variables:
      parent: this.parameterParent,
      positionAfterResource: this.parameterPositionAfterResource,
      toggleCreateMode: true,
      createText: null
    }
  },
  created: async function() {
    // Try block:
    try {
      // Log message:
      console.log("ResourceCreateComponent.created(): Entered method");
    } finally {
      // Log message:
      console.log("ResourceCreateComponent.created(): Exiting method");
    }
  },
  computed: {
  },
  watch: {},
  methods: {
    onToggle: async function() {
      // Try block:
      try {
        // Log message:
        log.message("ResourceCreateComponent.onToggle(): Entered method");
        // Check prior toggle state:
        if(this.toggleCreateMode == false) {
          // Set create text:
          this.createText = null;
          // Start timer for delayed execution:
          setTimeout(() => { // Use of arrow function (lambda) here allows use of keyword 'this' to reference current component
            // Log message:
            log.message("ResourceCreateComponent.onToggle(): Executing timer delayed code");
            // Set initial height based on size of content:
            this.$refs.resourceTextAreaElement.style.height = this.$refs.resourceTextAreaElement.scrollHeight + 'px';
            // Focus resource text area element:
            this.$refs.resourceTextAreaElement.focus();
          }, 50); // Milliseconds
        }
        // Switch toggle:
        this.toggleCreateMode = !(this.toggleCreateMode);
      } finally {
        // Log message:
        log.message("ResourceCreateComponent.onToggle(): Exiting method");
      }
    },
    onCreateConfirmation: async function() {
      // Try block:
      try {
        // Log message:
        log.message("ResourceCreateComponent.onCreateConfirmation(): Entered method");
        // Log message:
        log.message("ResourceCreateComponent.onCreateConfirmation(): Setting resource text", { "create text": this.createText });
        // Extract parent data:
        let parentData = this.parent.extractData({
          "id": true,
          "creationDate": true,
          "modificationDate": true
        });
        // Extract resource data:
        let resourceData = {
          "category": this.category,
          "text": this.createText
        };
        // Extract posirtion after resource data:
        let positionAfterResourceData = this.positionAfterResource.extractData({
          "id": true,
          "creationDate": true,
          "modificationDate": true
        });
        // Execute call:
        var response = await webRequestManager.request("post", "storage", "/resource.save.v1", { "parent": parentData, "resource": resourceData, "location": "after", "positionAfterResource": positionAfterResourceData });
        // Loop over child list of parent:
        for(let index = 0; index < this.parent.childList.length; index++) {
          // Check child:
          if(this.parent.childList[index].id == this.positionAfterResource.id) {
            // Add resource to parent:
            this.parent.childList.insertAt(index + 1, response['resource']);
            // Set parent:
            this.parent.childList[index + 1].parent = this.parent;
            // Register resouce in synchronization service:
            synchronizationService.registerResourceAndDescendents(this.parent.childList[index + 1]);
          }
        }
        // Reset toggle:
        this.toggleCreateMode = false;
      } finally {
        // Log message:
        log.message("ResourceCreateComponent.onCreateConfirmation(): Exiting method");
      }
    },
    onCreateCancellation: async function() {
      // Try block:
      try {
        // Log message:
        log.message("ResourceCreateComponent.onCreateCancellation(): Entered method");
        // Reset create mode toggle:
        this.toggleCreateMode = false;
        // Reset create text:
        this.createText = null;
      } finally {
        // Log message:
        log.message("ResourceCreateComponent.onCreateCancellation(): Exiting method");
      }
    },
    onResourceTextAreaKeyEnter: async function(event) {
      // Prevent browser default behavior:
      event.preventDefault();
      // Prevent triggering parent behavior:
      event.stopPropagation();
      // Call on create confirmation:
      this.onCreateConfirmation();
    },
    onChangeText: async function() {
      // Try block:
      try {
        // Log message:
        // log.message("ResourceCreateComponent.onChangeText(): Entered method");
        // DartHtml.querySelector()
        // Resize text area to match text value height:
        this.$refs.resourceTextAreaElement.style.height = this.$refs.resourceTextAreaElement.scrollHeight + 'px';
        // Log message:
        // log.message("ResourceCreateComponent.onChangeText(): Checking text, resource text = ${resourceText}");
      } finally {
        // Log message:
        // log.message("ResourceCreateComponent.onChangeText(): Exiting method");
      }
    }
  },
  filters: {
  }
}

</script>

<style lang="scss">

</style>

<template>
  <div>
    <div v-if="toggleCreateMode == false" style="height: 20px;" @click="toggleCreateMode = true"></div>
    <div v-if="toggleCreateMode == true">
      <!-- Create Resource --- Create Form -->
      <div class="input-group my-3">
        <!-- Create Resource --- Create Form --- Text Input -->
        <textarea class="form-control" ref="resourceTextAreaElement" rows="1" style="overflow: hidden; background-color: rgb(240, 240, 240);" v-model="createText" @keyup="onChangeText()" @keyup.enter="onResourceTextAreaKeyEnter()"></textarea>
        <div class="input-group-append">
          <!-- Create Resource --- Create Form --- Cancellation Button -->
          <span class="input-group-text" style="cursor: pointer;" @click="onCreateCancellation()">
            <svg width="1.25em" height="1.25em" viewBox="0 0 16 16" class="bi bi-x" fill="red" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>
          </span>
          <!-- Create Resource --- Create Form --- Confirmation Button -->
          <span class="input-group-text" style="cursor: pointer;" @click="onCreateConfirmation()">
            <svg width="1.25em" height="1.25em" viewBox="0 0 16 16" class="bi bi-check2" fill="green" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
            </svg>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
