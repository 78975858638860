
import { log, webRequestManager } from 'organizer-common';

export var synchronizationService = new class SynchronizationService {

  constructor() {
    // Try block:
    try {
      // Log message:
      console.log("SynchronizationService.constructor(): Entered method");
      // Initialize current category:
      this.currentCategory = null;
      // Initialize resource map:
      this.resourceMap = { };
      // Initialize monitor active identifier object:
      this.monitorActiveIdentifierObject = null;
    } finally {
      // Log message:
      console.log("SynchronizationService.constructor(): Exiting method");
    }
  }

  startMonitorForCategory(category) {
    // Try block:
    try {
      // Log message:
      log.message("SynchronizationService.startMonitorForCategory(): Entered method", { "category": category });
      // Initialize current category:
      this.currentCategory = category;
      // Reset resource map:
      this.resourceMap = { };
      // Reset monitor active identifier object
      this.monitorActiveIdentifierObject = { };
      // Trigger initial peek event:
      this.onPeekEvent(this.monitorActiveIdentifierObject);
    } finally {
      // Log message:
      log.message("SynchronizationService.startMonitorForCategory(): Exiting method");
    }
  }

  async onPeekEvent(monitorIdentifierObject) {
    // Try block:
    try {
      // Log message:
      log.message("SynchronizationService.onPeekEvent(): Entered method");
      // Initialize default peek rate (seconds between execution):
      var peekRate = 15;
      // Log message:
      log.message("SynchronizationService.onPeekEvent(): Checking if current monitor identifier object is active");
      // Check monitor identifier object:
      if(monitorIdentifierObject == this.monitorActiveIdentifierObject) {
        // Log message:
        log.message("SynchronizationService.onPeekEvent(): Current monitor identifier object is active");
        // Try block:
        try {
          // Check if page is currently visible:
          if(document.visibilityState == "visible") {
            // Execute call:
            var response = await webRequestManager.request("post", "synchronization", "/peek.v2", { "category": this.currentCategory });
            // Retrieve data:
            var peekMode = response['peekMode'];
            peekRate = response['peekRate'];
            var resourceMap = response['resourceMap'];
            // Log message:
            log.message("SynchronizationService.onPeekEvent(): Extracted response data", { "peek mode": peekMode, "peek rate": peekRate });
            // Log message:
            log.message("SynchronizationService.onPeekEvent(): Extracted resource map", { "length": Object.keys(resourceMap).length });
            // Log message:
            log.message("SynchronizationService.onPeekEvent(): Extracted resource map", { "json": JSON.stringify(resourceMap) });
            // Iterate over resource map:
            for(var key in resourceMap) {
              // Log message:
              log.message("SynchronizationService.onPeekEvent(): Iterating over resource map, retrieving resource for key", { "key": key });
              // Extract resource:
              var resource = resourceMap[key];
              // Check resource in registered resource map:
              if(this.resourceMap[key]) {
                // Log message:
                log.message("SynchronizationService.onPeekEvent(): Found resource in resource map");
                // Retrieve child list:
                var childList = this.resourceMap[key].childList;
                // Reset data: 
                this.resourceMap[key].resetData(resource);
                // Check child list:
                if(!this.resourceMap[key].childList || this.resourceMap[key].childList.length == 0) {
                  // Reattach child list:
                  this.resourceMap[key].childList = childList;
                }
                // Register resouce in synchronization service:
                this.registerResourceAndDescendents(this.resourceMap[key]);
              } else {
                // Log message:
                log.message("SynchronizationService.onPeekEvent(): Unable to find resource in resource map");
              }
            }
          } else {
            // Log message:
            log.message("SynchronizationService.onPeekEvent(): Skipping monitor, page is not currently visible");
          }
        } finally {
          // Start timer for delayed execution:
          setTimeout(() => this.onPeekEvent(monitorIdentifierObject), peekRate * 1000);
        }
      } else {
        // Log message:
        log.message("SynchronizationService.onPeekEvent(): Current monitor identifier object is not active");
      }
    } finally {
      // Log message:
      log.message("SynchronizationService.onPeekEvent(): Exiting method");
    }
  }

  registerResourceAndDescendents(resource) {
    // Try block:
    try {
      // Log message:
      log.message("SynchronizationService.registerResourceAndDescendents(): Entered method");
      // Set resource in map:
      this.resourceMap[resource.id] = resource;
      // Check child list:
      if(resource.childList) {
        // Loop through children and call recursively:
        for(let index = 0; index < resource.childList.length; index++) {
          // Register resource and descendents:
          this.registerResourceAndDescendents(resource.childList[index]);
        }
      }
    } finally {
      // Log message:
      log.message("SynchronizationService.registerResourceAndDescendents(): Exiting method");
    }
  }

}();
