
<script>

import { log, webRequestManager } from 'organizer-common';
import { SerializableObject } from 'organizer-common';

import { synchronizationService } from '@/service/SynchronizationService.js';

export default {
  name: 'TitleResourceCreateComponent',
  components: {
  },
  props: {
    parameterParent: Object,
    placeholder: String,
    category: String,
    depth: Number
  },
  data: function() {
    return {
      // Initialize variables:
      parent: this.parameterParent,
      createText: null
    }
  },
  created: async function() {
    // Try block:
    try {
      // Log message:
      console.log("TitleResourceCreateComponent.created(): Entered method");
    } finally {
      // Log message:
      console.log("TitleResourceCreateComponent.created(): Exiting method");
    }
  },
  computed: {
  },
  watch: {},
  methods: {
    onCreateConfirmation: async function() {
      // Try block:
      try {
        // Log message:
        log.message("TitleResourceCreateComponent.onCreateConfirmation(): Entered method");
        // Log message:
        log.message("TitleResourceCreateComponent.onCreateConfirmation(): Setting resource text", { "create text": this.createText });
        // Extract parent data:
        let parentData = this.parent.extractData({
          "id": true,
          "creationDate": true,
          "modificationDate": true
        });
        // Extract resource data:
        let resourceData = {
          "category": this.category,
          "text": this.createText
        };
        // Execute call:
        var response = await webRequestManager.request("post", "storage", "/resource/save/v1", { "parent": parentData, "resource": resourceData, "location": "prepend" });
        // Create resource:
        var resource = new SerializableObject(response['resource']);
        // Set parent:
        resource.parent = this.parent;
        // Add resource to parent:
        this.parent.childList.insertAt(0, response['resource']);
        // Set parent:
        this.parent.childList[0].parent = this.parent;
        // Register resouce in synchronization service:
        synchronizationService.registerResourceAndDescendents(this.parent.childList[0]);
        // Clear text:
        this.createText = null;
        // Reset text area height:
        this.$refs.resourceTextAreaElement.style.height = 'auto';
      } finally {
        // Log message:
        log.message("TitleResourceCreateComponent.onCreateConfirmation(): Exiting method");
      }
    },
    onResourceTextAreaKeyEnter: async function(event) {
      // Prevent browser default behavior:
      event.preventDefault();
      // Prevent triggering parent behavior:
      event.stopPropagation();
      // Call on create confirmation:
      this.onCreateConfirmation();
    },
    onChangeText: async function() {
      // Try block:
      try {
        // Log message:
        // log.message("TitleResourceCreateComponent.onChangeText(): Entered method");
        // DartHtml.querySelector()
        // Resize text area to match text value height:
        this.$refs.resourceTextAreaElement.style.height = this.$refs.resourceTextAreaElement.scrollHeight + 'px';
        // Log message:
        // log.message("TitleResourceCreateComponent.onChangeText(): Checking text, resource text = ${resourceText}");
      } finally {
        // Log message:
        // log.message("TitleResourceCreateComponent.onChangeText(): Exiting method");
      }
    }
  },
  filters: {
  }
}

</script>

<style lang="scss">

</style>

<template>
  <div class="input-group">
    <!-- Create Resource --- Create Form --- Text Input -->
    <textarea class="form-control" ref="resourceTextAreaElement" rows="1" style="overflow: hidden; background-color: rgb(240, 240, 240);" :placeholder="placeholder" v-model="createText" @keyup="onChangeText()" @keyup.enter="onResourceTextAreaKeyEnter()"></textarea>
    <div class="input-group-append">
      <!-- Create Resource --- Create Form --- Confirmation Button -->
      <span class="input-group-text" style="cursor: pointer;" @click="onCreateConfirmation()">
        <svg width="1.25em" height="1.25em" viewBox="0 0 16 16" class="bi bi-check2" fill="green" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
        </svg>
      </span>
    </div>
  </div>
</template>
