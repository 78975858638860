
<script>

import { log, errorHandler, webRequestManager } from 'organizer-common';

import { synchronizationService } from '@/service/SynchronizationService.js';
import { default as ResourceCreateComponent } from '@/ui/block/ResourceCreateComponent.vue';

export default {
  name: 'ResourceComponent',
  components: {
    ResourceCreateComponent
  },
  props: {
    parameterParent: Object,
    parameterResource: Object,
    depth: Number,
    flagHighlight: Boolean
  },
  data: function() {
    return {
      // Initialize variables:
      parent: this.parameterParent,
      resource: this.parameterResource,
      flagDisplayChildList: false,
      flagHighlightChildList: false,
      editToggle: false,
      editMode: null,
      editText: null,
      shareWithEmailAddress: null,
      toggleResourceCreateComponent: false,
      flagVisible: true
    }
  },
  created: async function() {
    // Try block:
    try {
      // Log message:
      console.log("ResourceComponent.created(): Entered method");
    } finally {
      // Log message:
      console.log("ResourceComponent.created(): Exiting method");
    }
  },
  computed: {
  },
  watch: {},
  methods: {
    onTaskCompletionToggle: async function() {
      // Try block:
      try {
        // Log message:
        log.message("ResourceComponent.onTaskCompletionToggle(): Entered method");
        // Check completion date:
        if(this.resource.completionDate == null) {
          // Retrieve current date string:
          var currentDateString = new Date().toJSON();
          // Set completion date:
          this.resource.set("completionDate", currentDateString);
          // Set progress bar element preliminary width:
          this.$refs.resourceTaskCompletionProgressBar.style.width = '0%';
          // Start timer to update display outside of current function:
          setTimeout(() => {
            // Set progress bar element completion width (with css transition):
            this.$refs.resourceTaskCompletionProgressBar.style.width = '100%';
          }, 1);
          // Start timer to hide current resource after completion of progress bar:
          setTimeout(() => {
            // Check completion date:
            if(this.resource.completionDate != null) {
              // Adjust resource wrapper element to a fixed max height (instead of auto):
              this.$refs.resourceWrapperElement.style.maxHeight = '100px';
              // Start timer to update display outside of current function:
              setTimeout(async () => {
                // Adjust resource wrapper element max height to zero to collapse resource (with css transition):
                this.$refs.resourceWrapperElement.style.maxHeight = '0px';
                // Set archive date:
                this.resource.set("archiveDate", currentDateString);
                // Extract parent data:
                let parentData = this.parent.extractData({
                  "id": true,
                  "creationDate": true,
                  "modificationDate": true
                });
                // Extract resource data:
                let resourceData = this.resource.extractData({
                  "id": true,
                  "category": true,
                  "completionDate": true,
                  "archiveDate": true,
                  "creationDate": true,
                  "modificationDate": true,
                  "text": true
                });
                // Execute call:
                var response = await webRequestManager.request("post", "storage", "/resource/save/v1", { "parent": parentData, "resource": resourceData, "location": "append" });
                // Repopulate resource from json:
                this.resource.resetData(response['resource']);
              }, 1);
            }
          }, 2000);
        } else {
          // Clear completion date:
          this.resource.completionDate = null;
          // Clear archive date:
          this.resource.archiveDate = null;
          // Reset progress bar element completion width:
          this.$refs.resourceTaskCompletionProgressBar.style.width = '0%';
          // Extract parent data:
          let parentData = this.parent.extractData({
            "id": true,
            "creationDate": true,
            "modificationDate": true
          });
          // Extract resource data:
          let resourceData = this.resource.extractData({
            "id": true,
            "category": true,
            "completionDate": true,
            "archiveDate": true,
            "creationDate": true,
            "modificationDate": true,
            "text": true
          });
          // Execute call:
          var response = await webRequestManager.request("post", "storage", "/resource/save/v1", { "parent": parentData, "resource": resourceData, "location": "append" });
          // Repopulate resource from json:
          this.resource.resetData(response['resource']);
        }
      } finally {
        // Log message:
        log.message("ResourceComponent.onTaskCompletionToggle(): Exiting method");
      }
    },
    onEditToggle: async function() {
      // Try block:
      try {
        // Log message:
        log.message("ResourceComponent.onEditToggle(): Entered method");
        // Check prior edit toggle state:
        if(this.editToggle == false) {
          // Set edit text:
          this.editText = this.resource.text;
          // Start timer for delayed execution:
          setTimeout(() => { // Use of arrow function (lambda) here allows use of keyword 'this' to reference current component
            // Log message:
            log.message("ResourceComponent.onEditToggle(): Executing timer delayed code");
            // Set initial height based on size of content:
            this.$refs.resourceTextAreaElement.style.height = this.$refs.resourceTextAreaElement.scrollHeight + 'px';
            // Focus resource text area element:
            this.$refs.resourceTextAreaElement.focus();
          }, 50); // Milliseconds
        }
        // Switch edit toggle:
        this.editToggle = !(this.editToggle);
      } finally {
        // Log message:
        log.message("ResourceComponent.onEditToggle(): Exiting method");
      }
    },
    onEditTextConfirmation: async function() {
      // Try block:
      try {
        // Log message:
        log.message("ResourceComponent.onEditTextConfirmation(): Entered method");
        // Log message:
        log.message("ResourceComponent.onEditTextConfirmation(): Setting resource text", { "edit text": this.editText });
        // Set text:
        this.resource.text = this.editText;
        // Extract parent data:
        let parentData = this.parent.extractData({
          "id": true,
          "creationDate": true,
          "modificationDate": true
        });
        // Extract resource data:
        let resourceData = this.resource.extractData({
          "id": true,
          "category": true,
          "completionDate": true,
          "creationDate": true,
          "modificationDate": true,
          "text": true
        });
        // Execute call:
        var response = await webRequestManager.request("post", "storage", "/resource/save/v1", { "parent": parentData, "resource": resourceData, "location": "append" });
        // Repopulate resource from json:
        this.resource.resetData(response['resource']);
        // Reset edit mode:
        this.editMode = null;
        // Reset edit toggle:
        this.editToggle = false;
      } finally {
        // Log message:
        log.message("ResourceComponent.onEditTextConfirmation(): Exiting method");
      }
    },
    onResourceTextAreaKeyEnter: async function(event) {
      // Prevent browser default behavior:
      event.preventDefault();
      // Prevent triggering parent behavior:
      event.stopPropagation();
      // Call on edit text confirmation:
      this.onEditTextConfirmation();
    },
    onChangeText: async function() {
      // Try block:
      try {
        // Log message:
        // log.message("ResourceComponentonChangeText(): Entered method");
        // DartHtml.querySelector()
        // Resize text area to match text value height:
        this.$refs.resourceTextAreaElement.style.height = this.$refs.resourceTextAreaElement.scrollHeight + 'px';
        // Log message:
        // log.message("ResourceComponentonChangeText(): Checking text, resource text = ${resourceText}");
      } finally {
        // Log message:
        // log.message("ResourceComponentonChangeText(): Exiting method");
      }
    },
    onIndentButton: async function() {
      // Try block:
      try {
        // Log message:
        log.message("ResourceComponent.onIndentButton(): Entered method");
        // Get original index:
        let originalIndex = this.parent.childList.indexOf(this.resource);
        // Log message:
        log.message("ResourceComponent.onIndentButton(): Found resource in child resource list of parent", { "original index": originalIndex });
        // Check parent:
        if(originalIndex > 0) {
          // Find resource above this resource:
          let newParentResource = this.parent.childList[originalIndex - 1];
          // Extract data:
          let newParentResourceData = newParentResource.extractData({
            "id": true,
            "creationDate": true,
            "modificationDate": true
          });
          // Extract data:
          let resourceData = this.resource.extractData({
            "id": true,
            "creationDate": true,
            "modificationDate": true
          });
          // Reposition resource:
          await webRequestManager.request("post", "storage", "/resource/reposition/v1", { "parent": newParentResourceData, "resource": resourceData, "location": "append" });
          // Get current resource:
          let resource = this.resource; // Current this.resource will change once removed from parent childList in next statement
          // Remove resource from parent:
          this.parent.childList.removeAt(originalIndex);
          // Add resource to new parent:
          newParentResource.childList.push(resource);
          // Set resource parent:
          resource.parent = newParentResource;
        }
      } finally {
        // Log message:
        log.message("ResourceComponent.onIndentButton(): Exiting method");
      }
    },
    onOutdentButton: async function() {
      // Try block:
      try {
        // Log message:
        log.message("ResourceComponent.onOutdentButton(): Entered method");
/*
        // Check if parent has a parent:
        if(this.parent.parent != null) {
          // Get original index:
          int originalIndex = this.parent.childResourceList.indexOf(this.resource);
          // Get parent index:
          int parentIndex = this.parent.parent.childResourceList.indexOf(this.parent);
          // Log message:
          log.message("ResourceComponent.onOutdentButton(): Found resource in child resource list of parent, original index = $originalIndex, parent index = $parentIndex");
          // Remove resource from parent:
          this.parent.childResourceList.removeAt(originalIndex);
          // Check if there is an element after parent:
          if(parentIndex + 1 < this.parent.parent.childResourceList.length) {
            // Get insert position resource:
            SimpleResource insertPositionResource = this.parent.parent.childResourceList[parentIndex + 1];
            // Reposition resource:
            await storageDataAccess.repositionResource(this.parent.parent, this.resource, insertPositionResource: insertPositionResource);
            // Add resource to new parent:
            this.parent.parent.childResourceList.insert(parentIndex + 1, resource);
            // Set resource parent:
            this.resource.parent = parent.parent;
          } else {
            // Reposition child resource:
            await storageDataAccess.repositionResource(this.parent.parent, this.resource, location: "append");
            // Add resource to new parent:
            this.parent.parent.childResourceList.add(this.resource);
            // Set resource parent:
            this.resource.parent = this.parent.parent;
          }
          // Loop over remaining children of parent:
          while(originalIndex < parent.childResourceList.length) {
            // Get child resource from parent:
            SimpleResource childResource = parent.childResourceList[originalIndex];
            // Reposition child resource:
            await storageDataAccess.repositionResource(this.resource, childResource, location: "append");
            // Remove child resource from parent:
            this.parent.childResourceList.removeAt(originalIndex);
            // Append child resource to current resource:
            this.resource.childResourceList.add(childResource);
            // Set child resource parent:
            childResource.parent = this.resource;
          }
        } else {
          // Log message:
          log.message("ResourceComponent.onOutdentButton(): No parent exists for parent resource");
        }
*/
      } finally {
        // Log message:
        log.message("ResourceComponent.onOutdentButton(): Exiting method");
      }
    },
    onArchiveButton: async function() {
      // Try block:
      try {
        // Log message:
        log.message("ResourceComponent.onArchiveButton(): Entered method");
      } finally {
        // Log message:
        log.message("ResourceComponent.onArchiveButton(): Exiting method");
      }
    },
    onShareButton: async function() {
      // Try block:
      try {
        // Log message:
        log.message("ResourceComponent.onShareButton(): Entered method");
        // Check edit mode:
        if(this.editMode != 'share') {
          // Set share with email address:
          this.shareWithEmailAddress = null;
          // Set edit mode:
          this.editMode = 'share';
        } else {
          // Reset edit mode:
          this.editMode = null;
        }
      } finally {
        // Log message:
        log.message("ResourceComponent.onShareButton(): Exiting method");
      }
    },
    onShareEmailCancellation: async function() {
      // Try block:
      try {
        // Log message:
        log.message("ResourceComponent.onShareEmailCancellation(): Entered method");
        // Reset edit mode:
        this.editMode = null;
      } finally {
        // Log message:
        log.message("ResourceComponent.onShareEmailCancellation(): Exiting method");
      }
    },
    onShareEmailConfirmation: async function() {
      // Try block:
      try {
        // Log message:
        log.message("ResourceComponent.onShareEmailConfirmation(): Entered method", { "shareWithEmailAddress": this.shareWithEmailAddress });
        // Execute call:
        await webRequestManager.request("post", "storage", "/resource/share.v1", { "resource": this.resource, "recipientAccountEmailAddress": this.shareWithEmailAddress });
        // Show dialog:
        // dialogService.showMessage("Successfully sent share request!");
        // Reset edit mode:
        this.editMode = null;
        // Reset edit toggle:
        this.editToggle = false;
      } catch(error) {
        // Show dialog:
        // dialogService.showMessage("Problem sending share request");
        // Handle error:
        errorHandler.handleError(error);
      } finally {
        // Log message:
        log.message("ResourceComponent.onShareEmailConfirmation(): Exiting method");
      }
    },
    onUnhighlight: function() {
      // Log message:
      log.message("ResourceComponent.onUnhighlight(): Entered method");
      // Reset flag to highlight child list:
      this.flagHighlightChildList = false;
      // Log message:
      log.message("ResourceComponent.onUnhighlight(): Exiting method");
    },
    onToggleChildren: async function() {
      // Try block:
      try {
        // Log message:
        log.message("ResourceComponent.onToggleChildren(): Entered method", { "resource id": this.resource.id });
        // Check child list:
        if(this.resource.childList == null || this.resource.childList.length == 0) {
          return;
        }
        // Check if children are currently showing:
        if(this.flagDisplayChildList == false) {
          // Set flag to display child list:
          this.flagDisplayChildList = true;
          // Set flag to highlight child list:
          this.flagHighlightChildList = true;
          // Emit event unhighlight:
          this.$emit('event-unhighlight')
          // Execute call:
          var response = await webRequestManager.request("post", "storage", "/resource.child.list.with.children.v1", { "resource": this.resource, "category": this.resource.category });
          // Reload resource with child list with children:
          this.resource.childList.resetData(response['resourceList']);
          // Set parent for resources in child resource list:
          for(let child of this.resource.childList) {
            // Log message:
            log.message("ResourceComponent.onToggleChildren(): Iterating over child in list", { "child text": child.text });
            // Set parent:
            child.parent = this.resource;
            // Set parent for resources in child resource list:
            for(let grandchild of child.childList) {
              // Log message:
              log.message("ResourceComponent.onToggleChildren(): Iterating over each child of child in list", { "grandchild text": grandchild.text });
              // Set parent:
              grandchild.parent = child;
            }
          }
          // Register resouce in synchronization service:
          synchronizationService.registerResourceAndDescendents(this.resource);
        } else {
          // Set flag to display child list:
          this.flagDisplayChildList = false;
          // Set flag to highlight child list:
          this.flagHighlightChildList = false;
        }
      } finally {
        // Log message:
        log.message("ResourceComponent.onToggleChildren(): Exiting method");
      }
    },
    onTest: function() {
      // Try block:
      try {
        // Log message:
        log.message("ResourceComponent.onTest(): Entered method");
      } finally {
        // Log message:
        log.message("ResourceComponent.onTest(): Exiting method");
      }
    }
  },
  filters: {
  }
}

</script>

<style lang="scss">

</style>

<template>
  <div v-if="resource" ref="resourceWrapperElement" style="overflow-y: hidden; transition: max-height 0.25s;">
    <!-- Resource View -->
    <div style="border-radius: 5px;" :style="{ 'position': 'relative', 'background-color': flagHighlight ? 'rgb(68, 79, 76)' : 'rgb(60, 60, 60)' }" class="cursor-clickable" @click="onToggleChildren()">
      <div ref="resourceTaskCompletionProgressBar" style="position: absolute; left: 0px; bottom: 0px; height: 100%; background-image: linear-gradient(90deg, rgb(60, 60, 60), rgb(85, 70, 70)); transition: width 2s cubic-bezier(.55,.09,.68,.53);"></div>
      <div class="d-flex" style="position: relative;">
        <!-- Resource View --- Indent -->
        <div style="margin: 0px 0px 0px 8px;"></div>
        <div v-for="(indent) in depth" :key="indent" style="margin: 0px 4px 0px 8px;">
        </div>
        <div v-if="resource.childList.length > 0" style="width: 16px; height: 16px; line-height: 16px; margin: 9px 0px 9px 0px; border-radius: 50%; background-color: rgb(142, 87, 47); text-align: center; font-size: 0.75rem;">{{ resource.childList.length }}</div><!-- 47, 125, 142 -->
        <!-- Resource View --- Task / Shopping Checkbox -->
        <div v-if="resource.childList.length == 0 && (resource.category == 'task' || resource.category == 'shop')" class="shaded-color" style="cursor: pointer;" @click="onTaskCompletionToggle()">
          <div v-if="resource.completionDate == null">
            <svg style="margin: 8px 4px;" width="18px" height="18px" viewBox="0 0 16 16" class="bi bi-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
            </svg>
          </div>
          <div v-if="resource.completionDate != null">
            <svg style="margin: 8px 4px;" width="18px" height="18px" viewBox="0 0 16 16" class="bi bi-check-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
              <path fill-rule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"/>
            </svg>
          </div>
        </div>
<!--
          <div v-if="resource.sharingCount == 1">
            <svg style="margin: 8px;" width="18px" height="18px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z"/></svg>
          </div>
-->
        <div v-if="resource.sharingCount > 1">
          <svg style="margin: 8px;" xmlns="http://www.w3.org/2000/svg"  width="18px" height="18px" viewBox="0 0 24 24" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M20 6h-8l-2-2H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-5 3c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm4 8h-8v-1c0-1.33 2.67-2 4-2s4 .67 4 2v1z"/></svg>
        </div>
        <!-- Resource View --- Show Text -->
        <div v-if="editToggle == false" style="margin: 5px;" :class="{ 'shaded-constant': resource.completionDate != null }">{{ resource.text }}</div>
        <!-- Resource View --- Edit Text -->
        <div v-if="editToggle == true" class="input-group">
          <!-- Resource View --- Edit Text --- Text Input -->
          <textarea class="form-control" ref="resourceTextAreaElement" rows="1" style="overflow: hidden;" v-model="editText" @keyup="onChangeText()" @keyup.enter="onResourceTextAreaKeyEnter()"></textarea>
          <div class="input-group-append">
            <!-- Resource View --- Edit Text --- Confirmation Button -->
            <span class="input-group-text" style="cursor: pointer;" @click="onEditTextConfirmation()">
              <svg width="1.25em" height="1.25em" viewBox="0 0 16 16" class="bi bi-check2" fill="green" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
              </svg>
            </span>
          </div>
        </div>
        <!-- Resource View --- Resource Edit Toggle -->
        <div class="ml-auto shaded-color cursor-clickable" style="padding: 5px 10px; line-height: 1.25em;" @click.stop="onEditToggle()">
          <div v-if="editToggle == false">
            <svg width="18px" height="18px" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
              <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
            </svg>
          </div>
          <div v-if="editToggle == true" style="font-size: 2em;">&times;</div>
        </div>
      </div>
      <!-- Edit Button Panel -->
      <div v-if="editToggle == true">
        <!-- Edit Button Panel --- Edit Menu -->
        <div class="d-flex align-items-center" style="margin: 0px 8px;">
          <!-- Edit Button Panel --- Edit Menu --- Outdent Button -->
          <div class="shaded-color" style="padding: 4px 4px 4px 6px; cursor: pointer;" @click="onOutdentButton()">
            <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" fill="currentColor" class="bi bi-box-arrow-in-left" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M10 3.5a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 1 1 0v2A1.5 1.5 0 0 1 9.5 14h-8A1.5 1.5 0 0 1 0 12.5v-9A1.5 1.5 0 0 1 1.5 2h8A1.5 1.5 0 0 1 11 3.5v2a.5.5 0 0 1-1 0v-2z"/>
              <path fill-rule="evenodd" d="M4.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H14.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"/>
            </svg>
          </div>
          <!-- Edit Button Panel --- Edit Menu --- Indent Button -->
          <div class="shaded-color" style="padding: 4px 6px 4px 4px; cursor: pointer;" @click="onIndentButton()">
            <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" fill="currentColor" class="bi bi-box-arrow-in-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"/>
              <path fill-rule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
            </svg>
          </div>
          <!-- Edit Button Panel --- Edit Menu --- Archive Button -->
          <div class="shaded-color" style="padding: 4px 6px; cursor: pointer;" @click="onArchiveButton()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="18px" height="18px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M20.54 5.23l-1.39-1.68C18.88 3.21 18.47 3 18 3H6c-.47 0-.88.21-1.16.55L3.46 5.23C3.17 5.57 3 6.02 3 6.5V19c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6.5c0-.48-.17-.93-.46-1.27zm-8.89 11.92L6.5 12H10v-2h4v2h3.5l-5.15 5.15c-.19.19-.51.19-.7 0zM5.12 5l.81-1h12l.94 1H5.12z"/></svg>
          </div>
          <!-- Edit Button Panel --- Edit Menu --- Share Button -->
          <div class="shaded-color" style="padding: 4px 6px; cursor: pointer;" @click="onShareButton()">
            <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16">
              <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
              <path fill-rule="evenodd" d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"/>
              <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>
            </svg>
          </div>
          <!-- Edit Button Panel --- Edit Menu --- Change Folder -->
<!--
          <div class="shaded-color" style="cursor: pointer;">
            <svg style="margin: 4px;" width="18px" height="18px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z"/></svg>
          </div>
-->
          <!-- Edit Button Panel --- Edit Menu --- Test Button -->
          <div class="ml-auto">
            <button type="button" class="btn btn-secondary btn-sm" style="padding: 2px; font-size: 0.5rem; line-height: 1;" @click="onTest()">TEST</button>
          </div>
        </div>
        <!-- Edit Resource --- Edit Text -->
<!--
        <div *ngIf="editMode == 'text'" class="input-group mb-3">
          <!- - Edit Resource --- Edit Text --- Text Input - ->
          <textarea class="form-control" #resourceTextArea rows="1" style="overflow: hidden;" v-model="editText" @keyup="onChangeText()" @keyup.enter="onResourceTextAreaKeyEnter"></textarea>
          <div class="input-group-append">
            <!- - Edit Resource --- Edit Text --- Cancellation Button - ->
            <span class="input-group-text" style="cursor: pointer;" @click="onEditTextCancellation()">
              <svg width="1.25em" height="1.25em" viewBox="0 0 16 16" class="bi bi-x" fill="red" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg>
            </span>
            <!- - Edit Resource --- Edit Text --- Confirmation Button - ->
            <span class="input-group-text" style="cursor: pointer;" @click="onEditTextConfirmation()">
              <svg width="1.25em" height="1.25em" viewBox="0 0 16 16" class="bi bi-check2" fill="green" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
              </svg>
            </span>
          </div>
        </div>
-->
        <!-- Edit Resource --- Share Resource -->
        <div v-if="editMode == 'share'" class="input-group mb-3">
          <!-- Edit Resource --- Share Resource --- Text Input -->
          <input type="text" placeholder="Enter the email address for the account you'd like to send the share invite" class="form-control" v-model="shareWithEmailAddress"/>
          <div class="input-group-append">
            <!-- Edit Resource --- Share Resource --- Cancellation Button -->
            <span class="input-group-text" style="cursor: pointer;" @click="onShareEmailCancellation()">
              <svg width="1.25em" height="1.25em" viewBox="0 0 16 16" class="bi bi-x" fill="red" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg>
            </span>
            <!-- Edit Resource --- Share Resource --- Confirmation Button -->
            <span class="input-group-text" style="cursor: pointer;" @click="onShareEmailConfirmation()">
              <svg width="1.25em" height="1.25em" viewBox="0 0 16 16" class="bi bi-check2" fill="green" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="flagDisplayChildList == true">
      <div style="height: 20px;"></div>
      <!-- Resource Child List -->
      <div v-for="child in resource.childList" :key="child.serializableSource.index">
        <div v-if="child.archiveDate == null">
          <ResourceComponent :parameterParent="resource" :parameterResource="child" :depth="depth + 1" :flagHighlight="flagHighlightChildList" @event-unhighlight="onUnhighlight"></ResourceComponent>
        </div>
      </div>
    </div>
    <div v-if="flagDisplayChildList == false">
      <div v-if="toggleResourceCreateComponent == false" style="height: 20px;" @click="toggleResourceCreateComponent = true"></div>
      <div v-if="toggleResourceCreateComponent == true">
        <ResourceCreateComponent :parameterParent="parent" :depth="depth" :category="resource.category" :parameterPositionAfterResource="resource" />
      </div>
    </div>
  </div>
</template>
